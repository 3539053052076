import { FC } from 'react'

import * as styles from './FourOhFour.module.css'

const FourOhFour: FC = () => {
  return (
    <div className={styles.fourOhFour}>
      <h1>404</h1>
    </div>
  )
}

export default FourOhFour
