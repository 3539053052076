import SEO from '@bradgarropy/gatsby-plugin-seo'
import FourOhFour from 'components/Pages/FourOhFour'
import { FC } from 'react'

const NotFoundPage: FC = () => {
  return (
    <>
      <SEO
        title="Not found | Hacking the JS Interview"
        facebook={{
          image: 'https://hackingthejsinterview.com/logo.png'
        }}
        twitter={{
          image: 'https://hackingthejsinterview.com/logo.png',
          card: 'summary_large_image'
        }}
      />

      <FourOhFour />
    </>
  )
}

export default NotFoundPage
